<template>
  <div class="form-group">
    <label class="form-label">
      Nome da qualificações
    </label>
    <small class="form-text text-muted">
      A qualificação é uma ou duas palavras que resumem o que aconteceu na ligação.
    </small>
    <small class="form-text text-muted">
      Por exemplo: Venda realizada; Proposta; Recado; Sem interesse;
    </small>
    <input v-model="qualification.name" type="text" class="form-control" :class="errorName ? 'is-invalid': ''">
    <div v-if="errorName" class="invalid-feedback">
      O campo nome é obrigatório
    </div>
  </div>
  <div class="form-group">
    <label class="form-label mb-1">
      Cor da qualificação
    </label>
    <small class="form-text text-muted">
      Escolha uma cor que melhor representa esta qualificação.
    </small>
    <small class="form-text text-muted">
      Por exemplo: verde ou azul para sucesso, vermelho para uma rejeição.
    </small>
    <div class="card">
      <div class="card-body">
        <div class="btn-group-toggle">
          <div class="row">
            <div class="col" v-for="color in colors" :key="color">
              <input v-model="qualification.color" :value="color" type="radio" class="btn-check" name="options"
                     :id="`option${color}`"
                     autocomplete="off"
                     checked="">
              <label class="btn btn-white" :for="`option${color}`">
                <span class="dot" :style="`background-color:${color}`"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Divider -->
  <hr class="mt-5 mb-5">

  <div class="row">
    <div class="col-12 col-md-6">
      <div class="form-group">

        <label class="form-label mb-1">
          Contato com a Pessoa Certa
        </label>

        <small class="form-text text-muted">
          Caso esta qualificação seja um contato com a pessoa certo (decisor).
        </small>

        <div class="form-check form-switch">
          <input v-model="qualification.dmc" class="form-check-input" id="switchTwo" type="checkbox">
          <label class="form-check-label" for="switchTwo"></label>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="form-group">
        <label class="form-label mb-1">
          Conversão
        </label>

        <small class="form-text text-muted">
          Caso esta qualificação signifique uma conversão para o seu negócio.
        </small>

        <div class="form-check form-switch">
          <input @change="activeDmc()" v-model="qualification.conversion" class="form-check-input" id="switchOne"
                 type="checkbox">
          <label class="form-check-label" for="switchOne"></label>
        </div>
      </div>
    </div>
  </div> <!-- / .row -->

  <!-- Divider -->
  <hr class="mt-5 mb-5">

  <!-- Buttons -->
  <button class="btn w-100 btn-primary" @click="save()">
    Salvar
  </button>
  <router-link to="/config/qualifications" class="btn w-100 btn-link text-muted mt-2">
    {{ backButtonLabel }}
  </router-link>
</template>

<script>
export default {
  name: "EditQualificationForm",

  data() {
    return {
      qualification: {
        name: '',
        conversion: false,
        dmc: false,
        behavior: 3,
        color: '#2c7be5',
        days_limit: 7
      },

      backButtonLabel: '',

      errorName: false,

      colors: ['#2c7be5', '#6e84a3', '#00d97e', '#e63757', '#f6c343', '#39afd1', '#edf2f9', '#12263f',]
    }
  },

  computed: {
    qualificationToUpdate() {
      return this.$store.getters['qualifications/getQualificationToUpdate'];
    }
  },

  methods: {
    activeDmc() {
      if (this.qualification.conversion) {
        this.qualification.dmc = true;
      }
    },

    save() {
      if (this.qualification.name === "") {
        this.errorName = true;
        return;
      } else {
        this.errorName = false;
      }

      if (Object.keys(this.qualificationToUpdate).length > 0) {
        this.$store.dispatch('qualifications/fetchUpdateQualification', {
          id: this.qualification.id,
          payload: this.qualification
        });
        this.$swal('Edição concluida!', 'Qualificação foi atualizada com sucesso!', 'success')
            .then(() => {
              this.backButtonLabel = 'Voltar';
            })
      } else {
        this.$store.dispatch('qualifications/fetchStoreQualification', this.qualification);
        this.$swal('Cadastro concluído!', 'Qualificação foi criada com sucesso!', 'success')
            .then(() => {
              this.backButtonLabel = 'Voltar';
            })
      }
    }
  },

  mounted() {
    if (Object.keys(this.qualificationToUpdate).length > 0) {
      this.qualification = this.qualificationToUpdate;
    }

    this.backButtonLabel = Object.keys(this.qualificationToUpdate).length > 0 ? 'Cancelar edição' : 'Cancelar cadastro';
  }
}
</script>

<style scoped>
.dot {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
</style>
