<template>
  <layout header-pre-title="configurações"
          :header-title="Object.keys(qualificationToUpdate).length > 0 ? 'Editar Qualificações' : 'Cadastrar Qualificação'"
          :center-content="true">
    <template v-slot:content>
      <edit-qualification-form></edit-qualification-form>
    </template>
  </layout>
</template>

<script>
import Layout from "../../../components/layout/Layout";
import EditQualificationForm from "../../../components/configs/qualifications/QualificationForm";

export default {
  components: {EditQualificationForm, Layout},
  name: "CreateOrUpdateQualifications",

  computed: {
    qualificationToUpdate() {
      return this.$store.getters['qualifications/getQualificationToUpdate'];
    }
  },
}
</script>

<style scoped>
</style>